import api from "@/base/utils/request";

// 获取商会精神详情
export const spiritDetail = data => {
  return api({
    url: "/admin/cyc/Spirit/detail",
    method: "post",
    data
  });
};

// 保存本期精神
export const saveDetail = data => {
    return api({
      url: "/admin/cyc/Spirit/save",
      method: "post",
      data
    });
  };