<template>
  <div class="spirit-content" v-loading="loading">
    <el-form size="medium" :model="spiritForm" :rules="rules" label-position="right" label-width="85px"
      class="small-form" ref="spiritForm" @submit.native.prevent>
      <el-form-item label="栏目名称：" prop="name">
        <el-input type="text" v-model="spiritForm.name" placeholder="请输入栏目名称"></el-input>
      </el-form-item>
      <el-form-item label="分类名称：" prop="issue">
        <el-input type="text" v-model="spiritForm.issue" placeholder="请输入分类名称，例如：第一期"></el-input>
      </el-form-item>
      <el-form-item label="选择会员：" prop="members">
        <div class="handle-btn">
          <el-button type="primary" size="mini" @click="openSelectMember = true">添加</el-button>
          <el-button size="mini" @click="handleDelete">移除</el-button>
        </div>
      </el-form-item>
    </el-form>
    <el-table :data="spiritForm.members" class="thead-light" :height="tableHeight < 300 ? '300' : tableHeight"
      style="width: 100%" @selection-change="handleSelectionChange" id="sortTable" row-key="id">
      <!-- 勾选 -->
      <el-table-column class="select-check" fixed type="selection" width="55"></el-table-column>
      <!-- 排序 -->
      <el-table-column width="100" label="排序">
        <span class="el-icon-sort my-handle cursor-pointer"></span>
      </el-table-column>
      <!-- 序号 -->
      <el-table-column width="100" type="index" :index="indexMethod" label="序号"></el-table-column>
      <!-- 操作 -->
      <el-table-column label="操作" width="100" fixed="right">
        <template slot-scope="scope">
          <el-button style="color: #3576ff" size="small" type="text" @click="deleteRow(scope.$index)">移除
          </el-button>
        </template>
      </el-table-column>
      <template v-for="(th, index) in thead">
        <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
          :min-width="th.minWidth || ''" :key="index">
          <template slot-scope="scope">
            <!-- 复选框 -->
            <el-checkbox v-if="th.prop === 'is_recommend'"
              v-model="spiritForm.members[scope.$index][th.prop]"></el-checkbox>
            <!-- 普通 -->
            <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <FixedActionBar>
      <el-button type="primary" size="medium" @click="formSubmit('spiritForm')" :loading="saveLoading">保存
      </el-button>
      <el-button size="medium" @click="cancelConfig">取消</el-button>
    </FixedActionBar>
    <SelectMember v-model="openSelectMember" :Members="spiritForm.members" @changeSelect="handleSelect" />
  </div>
</template>

<script>
import { spiritDetail, saveDetail } from "../api/add-spirit";
import SelectMember from "@/modules/common/components/MemberSelector";
import Sortable from "@/modules/page-renovation/js/Sortable.min.js";
import FixedActionBar from "@/base/layout/FixedActionBar";
export default {
  data() {
    return {
      spiritId: this.$route.params.id,
      spiritForm: {
        id: 0,
        name: "",
        issue: "",
        members: [],
      },
      thead: [
        { label: "会员名称", prop: "nickname", minWidth: 100 },
        { label: "架构/会内职务", prop: "title_name", minWidth: 150 },
        {
          label: "单位名称",
          prop: "work_place",
          minWidth: 150,
          sort: "custom",
        },
        {
          label: "单位职务",
          prop: "work_role",
          minWidth: 150,
          sort: "custom",
        },
        { label: "推荐", prop: "is_recommend", minWidth: 90 },
      ],
      rules: {
        name: [{ required: true, message: "请输入栏目名称", trigger: "blur" }],
        issue: [{ required: true, message: "请输入分类名称", trigger: "blur" }],
        members: [
          { required: true, validator: this.memberListPass, trigger: "change" },
        ],
      },
      tableHeight: window.innerHeight - 442,
      selectArr: [], //勾选的数组id
      loading: false,
      saveLoading: false,
      openSelectMember: false,
    };
  },
  methods: {
    // 获取商会精神详情
    getSpiritDetail(id) {
      this.loading = true;
      spiritDetail({ id })
        .then((res) => {
          const { data } = res;
          data.members.forEach((item) => {
            item.is_recommend = item.is_recommend ? true : false;
          });
          this.spiritForm = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 拖拽排序
    drag() {
      const el = document.querySelectorAll(
        "#sortTable .el-table__body > tbody"
      )[0];
      Sortable.create(el, {
        disabled: false,
        handle: ".my-handle",
        ghostClass: "sortable-ghost",
        setData: function (dataTransfer) {},
        onEnd: (e) => {
          let arr = this.spiritForm.members; // 获取表数据
          arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]); // 数据处理
          this.$nextTick(() => {
            this.spiritForm.members = [];
            arr.forEach((item, index) => {
              this.spiritForm.members.push(item);
            });
          });
        },
      });
    },
    // 序号计算
    indexMethod(index) {
      return index + 1;
    },
    // 列表勾选事件
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        //筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.user_id);
        });
      }
    },
    //点击列表移除
    deleteRow(i) {
      this.$msgbox
        .confirm("确定要移除该数据吗？", "提示", {
          type: "error",
        })
        .then((res) => {
          this.spiritForm.members.splice(i, 1);
        })
        .catch((err) => {});
    },
    // 批量移除
    handleDelete() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要移除选中数据吗？", "提示", {
            type: "error",
          })
          .then((res) => {
            this.spiritForm.members = this.spiritForm.members.filter((item) => {
              return this.selectArr.indexOf(item.user_id) < 0;
            });
          })
          .catch((err) => {});
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 获取选择会员
    handleSelect(e) {
      e.forEach((item) => {
        item.is_recommend = item.is_recommend ? true : false;
      });
      this.spiritForm.members = e;
    },
    //校验会员列表
    memberListPass(rule, value, callback) {
      if (!value.length) {
        callback(new Error("请添加本期会员"));
      } else {
        callback();
      }
    },
    // 保存
    formSubmit(keyWords) {
      this.$refs[keyWords].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.saveLoading = true;
          const data = { ...this.spiritForm };
          data.members.forEach((item) => {
            item.is_recommend = item.is_recommend ? 1 : 0;
          });
          data.members = JSON.stringify(data.members);
          saveDetail(data)
            .then((res) => {
              if (!res.data.status) {
                this.$msgbox
                  .confirm(res.msg, "提示", {
                    type: "error",
                    confirmButtonText: "前往",
                  })
                  .then((res) => {
                    this.$router.push({
                      name: "CycSpiritList",
                      params: { tab: "deleted" },
                    });
                  })
                  .catch((err) => {});
                this.loading = false;
                this.saveLoading = false;
                return;
              }
              this.$message.success("保存成功");
              this.timer = setTimeout(() => {
                this.loading = false;
                this.saveLoading = false;
                this.$router.push({ name: "CycSpiritList" });
              }, 1500);
            })
            .catch((err) => {
              this.loading = false;
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancelConfig() {
      this.$router.push({ name: "CycSpiritList" });
    },
    // 监听窗口变化
    listenResize() {
      this.tableHeight = window.innerHeight - 442;
    },
  },
  created() {
    if (this.spiritId) {
      this.getSpiritDetail(this.spiritId);
    }
    window.addEventListener("resize", this.listenResize);
  },
  mounted() {
    this.drag();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.listenResize);
    clearTimeout(this.timer);
  },
  beforeRouteEnter(to, from, next) {
    const type = to.params.type;
    switch (type) {
      case "add":
        to.meta.title = "新增";
        break;
      case "edit":
        to.meta.title = "编辑";
        break;
    }
    next();
  },
  components: { SelectMember, FixedActionBar },
};
</script>

<style lang="scss" scoped>
.spirit-content {
  button {
    & + button {
      margin-left: 20px;
    }
  }

  .footer-btn {
    padding: 30px 40px 10px;
  }
}
</style>
